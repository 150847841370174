<template>
  <div class="query_wrap">
    <h1 class="h_none">商标查询</h1>
    <h2 class="h_none">蓝灯鱼商标查询,蓝灯鱼商标检索,商标,商标查询,商标检索</h2>
    <img src="/images/result_show_logo.png" alt="" class="h_none">
    <!-- <headers></headers> -->
    <div style="height: 90px"></div>
    <div class="query_wrap_cen">
      <div class="title">
        <span @click="jump('/entrance')">首页 &nbsp; >  &nbsp; </span>
        <span @click="jump('/PQ')">商标查询  &nbsp; >  &nbsp; </span>
        <span>商标对比</span>
      </div>
      <div class="upload">
        <div class="upload_left">
          <div class="upload_left_title">上传官方文件对比</div>
          <el-upload
            class="upload-demo"
            drag
            action=""
            :auto-upload="false"
            :on-change="uploadChangeFns"
            :show-file-list="false"
            multiple>
            <i class="picture">
              <img src="../../assets/images/contrast/upload.png" alt="">
            </i>
            <div class="el-upload__text1">点击或拖入文件</div>
            <div class="el-upload__text2">支持格式为PDF的驳回通知书</div>
          </el-upload>
        </div>

        <div class="upload_right">
          <div class="upload_right_title">手动输入商标号对比</div>
          <div class="content">
            <el-form :label-position="labelPosition" label-width="120px">
              <el-form-item label="申请商标号">
                <el-input v-model="formLabelAlign.appno"></el-input>
              </el-form-item>
              <el-form-item label="引证商标号">
                <el-input placeholder="标号之间以分号(;)分开" v-model="formLabelAlign.refno"></el-input>
              </el-form-item>
            </el-form>
          </div>
          <div @click="comparison" class="upload_btn">对比</div>
        </div>
      </div>

      <div class="table">
        <div class="table_title">
          <p>商标对比结果</p>
          <div>
            <!--            <img  style="cursor: pointer;margin-right: 10px" src="../../assets/images/query/doc.png" slot="reference"/>-->
            <img @click="Exexport()" style="cursor: pointer" src="../../assets/images/query/xls.png" slot="reference"/>
          </div>
        </div>
        <template>
          <div class="table_content_fs">
            <div class="table_content">
              <table style="position: sticky;left: 0;background: white;float: left" border="0" cellspacing="0"
                     cellpadding="0">
                <thead>
                <tr>
                  <th style="width: 106px"></th>
                  <th style="width: 343px">申请商标</th>
                  <template v-if="tableDatas.length">
                    <th style="width: 343px" v-for="item in (tableDatas.length - 1) ">引证商标{{item}}</th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td>申请号</td>
                  <td v-for="(item,index) in tableDatas" :key="index">{{item.appno}}</td>
                </tr>
                <tr>
                  <td>商标名称</td>
                  <td v-for="(item,index) in tableDatas" :key="index">{{item.sbmc}}</td>
                </tr>
                <tr>
                  <td>商标图样</td>
                  <td style="height: 105px" v-for="(item,index) in tableDatas" :key="index">
                    <img v-if="item.imageUrl" style="height: 100px!important;width: auto;max-width: 300px"
                         :src="'https://tmimage.lanternfish.cn' + item.imageUrl" alt="">
                  </td>
                </tr>
                <tr>
                  <td>申请日期</td>
                  <td v-for="(item,index) in tableDatas" :key="index">{{item.appdate}}</td>
                </tr>
                <tr>
                  <td>专用权起始日</td>
                  <td v-for="(item,index) in tableDatas" :key="index">{{item.regdate}}</td>
                </tr>
                <tr>
                  <td>申请人</td>
                  <td v-for="(item,index) in tableDatas" :key="index">{{item.applicant}}</td>
                </tr>
                <tr>
                  <td>类别</td>
                  <td v-for="(item,index) in tableDatas" :key="index">{{item.intcls}}</td>
                </tr>
                <tr style="background: rgba(231,235,244,0.75)">
                  <td>共有群组</td>
                  <td v-for="(items,indexs) in tableDatas"></td>
                </tr>
                <tr v-if="demo.length > 0 && formLabelAlign.refno" v-for="(item,index) in demo">
                  <td>{{item.similar}}</td>
                  <td style="display: block;line-height: 58px" :title="demo[index]['tmDetail' + indexs]" v-if="" v-for="(items,indexs) in tableDatas">{{demo[index]['tmDetail' + indexs] ?
                    demo[index]['tmDetail' + indexs] : '/'}}
                  </td>
                </tr>
                <tr style="background: rgba(231,235,244,0.75)">
                  <td>非共有群组</td>
                  <td v-for="(items,indexs) in tableDatas"></td>
                </tr>
                <tr v-if="demos.length > 0 && formLabelAlign.refno" v-for="(item,index) in demos">
                  <td>{{item.similar}}</td>
                  <td  style="display: block;line-height: 58px" :title="demos[index]['tmDetail' + indexs]" v-if="" v-for="(items,indexs) in tableDatas">{{demos[index]['tmDetail' + indexs] ?
                    demos[index]['tmDetail' + indexs] : '/'}}
                  </td>
                </tr>

                </tbody>
              </table>
              <table border="0" cellspacing="0" cellpadding="0">
                <thead>
                <tr>
                  <template v-if="tableDatad.length">
                    <th style="width: 343px" :key="index" v-for="(item,index) in (tableDatad.length) ">
                      引证商标{{item}}
                      <i v-if="tableDatad.length > 2"  style="" @click="deleteBtn(index)" class="el-icon-circle-close el_close"></i>
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <tr>
                  <td v-for="(item,index) in tableDatad" :key="index">{{item.appno}}</td>
                </tr>
                <tr>
                  <td v-for="(item,index) in tableDatad" :key="index">{{item.sbmc}}</td>
                </tr>
                <tr>
                  <td style="height: 105px" v-for="(item,index) in tableDatad" :key="index">
                    <img v-if="item.imageUrl" style="height: 100px!important;width: auto;max-width: 300px"
                         :src="'https://tmimage.lanternfish.cn' + item.imageUrl" alt="">
                  </td>
                </tr>
                <tr>
                  <td v-for="(item,index) in tableDatad" :key="index">{{item.appdate}}</td>
                </tr>
                <tr>
                  <td v-for="(item,index) in tableDatad" :key="index">{{item.regdate}}</td>
                </tr>
                <tr>
                  <td v-for="(item,index) in tableDatad" :key="index">{{item.applicant}}</td>
                </tr>
                <tr>
                  <td v-for="(item,index) in tableDatad" :key="index">{{item.intcls}}</td>
                </tr>
                <tr style="background: rgba(231,235,244,0.75)">
                  <td v-for="(items,indexs) in tableDatad"></td>
                </tr>
                <tr v-if="demo.length > 0 && formLabelAlign.refno" v-for="(item,index) in demo">
                  <td :style="{display: demo[index]['tmDetail' + (indexs + 1)] && demo[index]['tmDetail' + (indexs + 1)].length > 70 ? '' : ''}" :title="demo[index]['tmDetail' + (indexs + 1)]" v-for="(items,indexs) in tableDatad">{{demo[index]['tmDetail' + (indexs + 1)] ?
                    demo[index]['tmDetail' + (indexs + 1)] : '/'}}
                  </td>
                </tr>
                <tr style="background: rgba(231,235,244,0.75)">
                  <td v-for="(items,indexs) in tableDatad"></td>
                </tr>
                <tr v-if="demos.length > 0 && formLabelAlign.refno" v-for="(item,index) in demos">
                  <td :style="{display: demos[index]['tmDetail' + (indexs + 1)] && demos[index]['tmDetail' + (indexs + 1)].length > 70 ? '' : ''}" :title="demos[index]['tmDetail' + (indexs + 1)]"  v-for="(items,indexs) in tableDatad">{{demos[index]['tmDetail' + (indexs + 1)] ?
                    demos[index]['tmDetail' + (indexs + 1)] : '/'}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </template>
      </div>

    </div>
    <footers></footers>
  </div>

</template>

<script>

</script>

<script>
  import {customizedOrder, priceList} from "@/request/trademarkPayment";
  import {mapState, mapMutations, mapGetters} from 'vuex'
  import login from "@/components/common/login"
  import footers from '@/components/common/xfooter'

  export default {
    name: "contrast",
    data() {
      return {
        //申请商标号
        flag1: '',
        //引证商标号
        flag2: '',
        errFlag: false,
        //非冲突群组
        demo: [],
        //冲突群组
        demos: [],
        appnoArrs: '',
        number: 1,
        arrs: [],
        arr: [],
        //非冲突群组
        qarr: [],
        headers: {
          'Authorization': "Bearer " + this.tool.getCookie("auth"),
          'TENANT-ID': '4'
        },
        labelPosition: 'right',
        formLabelAlign: {
          appno: '',
          refno: ''
        },
        catalogue: [
          {
            text: '申请号'
          },
          {
            text: '商标名称'
          },
          {
            text: '商标图样'
          },
          {
            text: '申请日期'
          },
          {
            text: '专利权起始日'
          },
          {
            text: '申请人'
          },
          {
            text: '类别'
          },
          {
            text: '共有群组'
          },
          {
            text: '非共有群组'
          },
        ],
        tableData: [],
        table: [],
        queryAppno: '',
        tableDatas: [{}]
      }
    },
    components: {
      footers
    },
    mounted() {
      if (this.$route.query.appno) {
        this.formLabelAlign.appno = this.$route.query.appno.split(";").slice(0, 1).join(";")
        this.formLabelAlign.refno = this.$route.query.appno.split(";").slice(1).join(";")
        this.comparison(this.$route.query.appno)
      }
    },
    methods: {
      //删除引证商标
      deleteBtn(index){
        this.tableData.splice(index+1,1)
      },
      //表格导出
      Exexport(item) {
        if (!this.appnoArrs.length) {
          return;
        }
        const myDate = new Date();
        const Y = myDate.getFullYear();
        const M = (myDate.getMonth() < 9) ? "0" + (myDate.getMonth() + 1) : myDate.getMonth() + 1;
        const D = (myDate.getDate() < 10) ? "0" + myDate.getDate() : myDate.getDate();
        let date = Y + '-' + M + '-' + D;
        this.$axios.post('/common/query/tmCompare/excelExport', {
          "appNo": this.appnoArrs,
          "country": "CN"
        }, {responseType: "arraybuffer"}, {timeout: 60 * 1000 * 25}).then((res) => { // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content]);
          let fileName = ''
          fileName = '商标对比结果-' + date + '.xls';
          if ('download' in document.createElement('a')) { // 非IE下载
            const elink = document.createElement('a');
            elink.download = fileName;
            elink.style.display = 'none';
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink)
          } else { // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
          .catch((error) => {
            console.log(error);
          })
      },
      jump(path) {
        this.$router.push(path)
      },
      async uploadChangeFns(file) {
        let suffixArr = ["pdf"];
        let suffix = file.name.split('.').pop().toLowerCase();
        if (!suffixArr.includes(suffix)) {
          this.$message(this.$t('patentTranslation.errMessage'));
          return;
        }

        let params = {
          pdfFile: file.raw
        }
        const formData = new FormData();
        for (let i in params) {
          formData.append(i, params[i]);
        }
        let data = await this.$axios.post(
          '/lantern/api/trademark/ocr/officalfile', formData)
        if (data.data.code == 0) {
          this.$message({
            showClose: true,
            message: '文件上传成功',
            type: 'success'
          });
          this.formLabelAlign.appno = data.data.data.appno
          this.formLabelAlign.refno = data.data.data.refno
        } else {
          // this.errorCommon(data);
          this.$message('文件上传失败');
        }
      },
      comparison(appnoArrs) {
        if(this.errFlag){
          return;
        }
        if (typeof appnoArrs != 'string') {
          appnoArrs = ''
        }
        if (!appnoArrs && !this.formLabelAlign.appno) {
          this.$message('申请商标号不能为空');
          return;
        }
        let arr = []
        if (this.formLabelAlign.appno && this.formLabelAlign.appno.indexOf("；") != -1) this.formLabelAlign.appno = this.formLabelAlign.appno.replace(/；/g, ";")
        if (this.formLabelAlign.refno && this.formLabelAlign.refno.indexOf("；") != -1) this.formLabelAlign.refno = this.formLabelAlign.refno.replace(/；/g, ";")
        if (this.formLabelAlign.appno && this.formLabelAlign.refno) {
          arr = this.formLabelAlign.appno.split(";").concat(this.formLabelAlign.refno.split(";"))
        } else if (this.formLabelAlign.appno && !this.formLabelAlign.refno) {
          arr = this.formLabelAlign.appno.split(";")
        } else if (!this.formLabelAlign.appno && this.formLabelAlign.refno) {
          arr = this.formLabelAlign.refno.split(";")
        }
        this.$axios.post('/common/query/tmCompare', {
          "appNo": appnoArrs ? appnoArrs : arr.join(";").replace(/\s*/g,""),
          "country": "CN"
        }).then(({data}) => {
          if (data.code == 0) {
            this.table = data.data
            this.tableData = data.data
            this.appnoArrs = appnoArrs ? appnoArrs : arr.join(";")
              this.arr = []
            this.arrs = []
            //最大非冲突群组
            data.data.map((item, index) => {
              if (item.nonSharegroup && item.nonSharegroup.length > 0) {
                item.nonSharegroup.map((items, indexs) => {
                  items['tmDetail' + index] = items.tmDetail
                  this.arr.push(items)
                  return items;
                })
              }
              return item;
            })
            //冲突群组
            data.data.map((item, index) => {
              if (item.sharegroup && item.sharegroup.length > 0) {
                item.sharegroup.map((items, indexs) => {
                  items['tmDetail' + index] = items.tmDetail
                  this.arrs.push(items)
                  return items;
                })
              }
              return item;
            })
            //冲突群组
            this.demo = []
            let idArr = []
            idArr = this.arrs.filter(item => {
              return item.similargroup;
            }).length > 0 ? this.arrs.map(item => {
              return item.similargroup;
            }) : []
            idArr = idArr.filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });
            idArr.map(item => {
              this.demo.push({
                similar: item
              })
              return item;
            })
            idArr.map((item, index) => {
              this.arrs.map((items, indexs) => {
                if (item == items.similargroup) {
                  //合并
                  //this.demo[index] = this.demo[index] + items
                  for (let it in items) {
                    if (Object.keys(this.demo[index]).includes(it)) {
                      this.demo[index][it] = this.demo[index][it] + ";" + items[it]
                    } else {
                      this.demo[index][it] = items[it]
                    }
                  }
                }
                return items;
              })
              return item;
            })


            //非冲突群组
            this.demos = []
            let idArrs = []
            idArrs = this.arr.filter(item => {
              return item.similargroup;
            }).length > 0 ? this.arr.map(item => {
              return item.similargroup;
            }) : []
            idArrs = idArrs.filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });
            idArrs.map(item => {
              this.demos.push({
                similar: item
              })
              return item;
            })
            idArrs.map((item, index) => {
              this.arr.map((items, indexs) => {
                if (item == items.similargroup) {
                  //合并
                  //this.demos[index] = this.demos[index] + items
                  for (let it in items) {
                    if (Object.keys(this.demos[index]).includes(it)) {
                      this.demos[index][it] = this.demos[index][it] + ";" + items[it]
                    } else {
                      this.demos[index][it] = items[it]
                    }
                  }
                }
                return items;
              })
              return item;
            })
            this.number = this.tableData.length
            if (this.tableData.length == 1) {
              this.tableData.push({}, {})
            } else if (this.tableData.length == 2) {
              this.tableData.push({})
            }

            if (this.tableData.length > 1) {
              this.tableDatas = this.tableData.slice(0, 1)
            } else if (this.tableData.length = 1) {
              this.tableDatas = this.tableData
            } else {
              this.tableDatas = [{}]
            }
          }
        })
      }
    },
    computed: {
      shu() {
        return this.tableData.length
      },

      tableDatad() {
        if (this.tableData.length > 1) {
          return this.tableData.slice(1)
        } else if (this.tableData.length = 1) {
          return [{}, {}]
        } else {
          return [{}, {}]
        }
      }
    },
    watch: {
      formLabelAlign:{
        handler (val, olVal) {
          // if(val.appno.length < olVal.appno.length) return;
          // if(val.refno.length < olVal.refno.length) return;
          this.formLabelAlign.appno = this.formLabelAlign.appno.trim()
          this.formLabelAlign.refno = this.formLabelAlign.refno.trim()
          if( this.formLabelAlign.appno.length > 50 || this.formLabelAlign.refno.length > 120) {
            //   //公告期号
            this.$message('您查询内容的长度已超过限制，请确认是否输入正确，如无误，请分批查询');
            this.errFlag = true;
            return;
          }else {
            this.errFlag = false;
          }
        },
        deep: true
      },
      shu(newValue, oldValue) {
        this.$nextTick(() => {
          let div = document.querySelector('.table_content') // 获取dom元素
          if (newValue) {
            div.style.setProperty('width', 106 + 335 * newValue + "px")
          }
        })

      }
    }
  }
</script>
<style lang="less" scoped>
  .query_wrap {
    background: #F1F3FA;

    .query_wrap_cen {
      width: 1200px;
      margin: 0 auto;

      .title {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        position: relative;
        padding-left: 15px;
        box-sizing: border-box;
        margin-top: 44px;
        margin-bottom: 33px;

        &:before {
          width: 4px;
          height: 18px;
          content: '';
          position: absolute;
          top: 0px;
          left: 0;
          display: inline-block;
          background: #1A3DBF;
        }

        span:nth-of-type(3) {
          font-size: 14px;
          font-weight: 400;
          color: #444444;
        }

        span {
          cursor: pointer;

          &:hover {
            font-size: 14px;
            font-weight: 400;
            color: #444444;
          }
        }
      }
    }

    .upload {
      width: 1200px;
      height: 295px;
      background: #E7EBF4;
      display: flex;
      justify-content: space-between;
      padding: 16px 45px 71px 44px;
      box-sizing: border-box;

      .upload_left {
        .upload_left_title {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          position: relative;
          padding-left: 11px;
          box-sizing: border-box;

          &:before {
            width: 4px;
            height: 18px;
            position: absolute;
            content: '';
            top: 2px;
            left: 0;
            background: #697ECC;
          }
        }

        .upload-demo {
          margin-top: 26px;

          /deep/ .picture {
            display: block;
            margin: 35px 0 15px 0;
          }

          /deep/ .el-upload-dragger {
            width: 418px;
            height: 160px;
            background: #FFFFFF;
          }

          /deep/ .el-upload__text1 {
            font-size: 14px;
            font-weight: 400;
            color: #4E5A87;
            margin-bottom: 5px;
          }

          /deep/ .el-upload__text2 {
            font-size: 12px;
            font-weight: 400;
            color: #888D9D;
            letter-spacing: 1px;
          }
        }
      }

      .upload_right {
        position: relative;

        .upload_right_title {
          font-size: 14px;
          font-weight: 500;
          color: #6C7BB1;
          padding-top: 18px;
          box-sizing: border-box;
          padding-left: 27px;
        }

        .content {
          width: 582px;
          height: 162px;
          background: #FFFFFF;
          border-radius: 12px;
          margin-top: 10px;
          padding: 23px 0 0 0;
          box-sizing: border-box;

          /deep/ .el-input__inner {
            width: 420px;
            height: 28px;
            background: #E7EBF4;
            border-radius: 5px;
            opacity: 0.75;
            border: 1px solid #BDCBFF;
          }

          /deep/ .el-form-item:nth-of-type(2) .el-input__inner {
            height: 50px !important;
          }
        }

        .upload_btn {
          cursor: pointer;
          width: 88px;
          height: 34px;
          text-align: center;
          background: #2E54D9;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 34px;
          letter-spacing: 1px;
          position: absolute;
          bottom: -50px;
          right: 46px;
        }
      }
    }

    .table {
      /*width: 2000px;*/
      overflow: auto;
      min-height: 654px;
      margin-top: 38px;
      background: #FFFFFF;
      padding: 0 31px;
      box-sizing: border-box;

      .table_title {
        margin: 22px 33px 24px 0px;
        display: flex;
        justify-content: space-between;

        p {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          position: relative;
          padding-left: 9px;

          &:before {
            width: 4px;
            height: 18px;
            content: '';
            position: absolute;
            top: 2px;
            left: 0;
            background: #697ECC;
          }
        }
      }

      .table_content_fs {
        width: 1135px;
        overflow: auto;
      }

      .table_content {
        display: flex;
        /*width: 1900px;*/
        height: 500px;
        width: auto;
        /*overflow: hidden;*/
        /*overflow: auto;*/
        border-radius: 15px 15px 0px 0px;

        tr {
          /*line-height: 38px;*/
          height: 60px;

          td {
            height: 58px;
            overflow: hidden;
            text-align: center;
            font-size: 12px;
            font-weight: 400;
            color: #555555;
            border: 1px solid #CDD6E8;
          }
        }

        tr:nth-of-type(1) {
          height: 51px;
          font-size: 14px;
          font-weight: 500;
          color: #555555;

          th {
            text-align: center;
            background: rgba(211, 219, 248, 0.55);
            border-right: 1px solid #CDD6E8;
          }

          th:nth-of-type(1) {
            background: rgba(211, 219, 248);
            border-right: 1px solid #CDD6E8;
          }

          th:nth-of-type(2) {
            background: rgba(211, 219, 248);
            border-right: 1px solid #CDD6E8;
          }
        }
      }
    }
  }

</style>
<style>
</style>

<style>
  .dataTables_scrollBody #tableId {
    border-collapse: separate !important;
  }
</style>
